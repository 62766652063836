.user-info-header-container {
    padding: 20px;
    border-radius: 6px;
    border: 1px solid #e2e2e2;
    margin-bottom: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 5%);
}

.user-info-header-container span {
    font-size: 18px;
    font-weight: 900;
    color: #2a2a2a;
}

.user-info-header-container div span {
    font-size: 13px;
    margin-right: 7px;
}

.user-info-forms-container {
    display: flex;
    border-radius: 6px;
    border: 1px solid #e2e2e2;
    background-color: white;
    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 5%);
    width: 100%;
}

.user-info-form-info-update-div {
    padding: 20px;
}

.user-info-form-update-button {
    background-color: white;
    color: #f27a21;
    font-size: 14px;
}

.user-info-form-header-div {
    margin-bottom: 20px;
}

.user-info-form-header-div span {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: #f27a21;
}

.user-info-form-label {
    margin-bottom: 8px;
    color: #333333;
    font-size: 14px;
    font-weight: 900;
}

.user-info-form-control {
    height: 44px;
}

.user-info-form-control-div {
    display: flex;
    flex-direction: row-reverse;
}

.user-info-form-control-div i {
    position: absolute;
}

.user-info-form-control-icon {
    padding: 10px;
    font-size: 18px;
}

.user-info-form-control-validation {
    border: 1px solid #b30000;
    background-color: #fff1f1;
}

.user-info-form-control-validation:focus {
    border: 1px solid #b30000;
    background-color: #fff1f1;
}

.new-password-input-text {
    font-size: 13px;
    color: #1b1b1b;
}

.new-password-input-text-validation {
    color: #b30000;
}

.user-info-alert {
    border: 1px solid #f5eadf;
    background-color: #FFF7F0;
    padding: 20px;
    border-radius: 4px;
    margin: 15px 0;
    display: flex;
    align-items: center;
}

.user-info-alert p {
    margin-left: 10px;
    color: #333333;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 0;
}
.user-info-alert i{
    color: #f27a21;
}
.user-info-phone-button{
    margin-top: 29px;
    height: 44px;
    font-size: 14px;
    width: 100%;
}
