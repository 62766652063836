.bi-exclamation-circle {
    margin-right: 5px;
}

.bi-facebook {
    color: #ffffff;
}

.bi-google {
    color: #ffffff;
}

.card-form-tabs {
    background-color: #f2f2f2;
    font-size: 16px;
}

.email-valid-text {
    color: #b30000;
}

.facebook-icon {
    background-color: #4b6ea8;;
    border-radius: 4px;
    width: 30px;
    height: 30px;
}

.gender-button-selected {
    background-color: #f2f2f2;
    border-color: #f27a21;
    color: #f27a21;
    border-radius: 0;
    --bs-btn-active-bg: #f2f2f2;
    --bs-btn-active-border-color: #e6e6e6;
    --bs-btn-active-color: #666666;
}

.gender-button-selected:hover {
    background-color: #f2f2f2;
    border-color: #f27a21;
    color: #f27a21;
}

.gender-button {
    background-color: #f2f2f2;
    border-color: #e6e6e6;
    color: #666666;
    border-radius: 0;
    --bs-btn-active-bg: #f2f2f2;
    --bs-btn-active-border-color: #e6e6e6;
    --bs-btn-active-color: #666666;
}

.gender-button:hover {
    background-color: #f2f2f2;
    border-color: #e6e6e6;
    color: #666666;
}

.google-icon {
    background-color: #f04236;
    border-radius: 4px;
    width: 30px;
    height: 30px;
}

.label {
    font-size: 15px;
}

.login-sign-up-card {
    width: 374px;
    padding: 24px;
}

.login-page-sign-up {
    font-size: 12px;
    line-height: 14px;
}

.login-text-validation {
    color: #b30000;
    background-color: #fff1f1;
    padding: 10px;
    border: 1px solid #b30000;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.nav-div {
    width: 374px;
}

.password-text-validation {
    color: #b30000;
}

.sign-up-page-text {
    font-size: 12px;
    line-height: normal;
    margin-left: 10px;
}

.sign-up-page-text-validation {
    color: #b30000;
    font-size: 12px;
    line-height: normal;
    margin-left: 10px;
    font-weight: bold;
}

.sign-button {
    width: 148px;
    margin-right: 15px;
}

.sign-button1 {
    width: 148px;
}

.sign-up-validation-input {
    border: 1px solid #b30000;
    background-color: #fff1f1;
}

.sign-up-validation-input:focus {
    border: 1px solid #b30000;
    background-color: #fff1f1;
}

.sign-up-input-container i {
    position: absolute;
}

.sign-up-input-container {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
}

.sign-up-input-icon {
    padding: 10px;
    font-size: 18px;
}

.sign-up-input {
    padding-right: 30px;
}

