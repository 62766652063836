.favorite-page-container {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.favorite-page-header-container {
    height: 70px;
    width: 100%;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.08);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
}

.favorite-header-item-div {
    width: 1200px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.favorite-header-item-first i {
    margin-right: 10px;
}

.favorite-header-item-first {
    display: flex;
    align-items: center;
    height: 100%;
}

.favorite-header-search-input {
    width: 330px;
    display: flex;
    flex-direction: row-reverse;
}

.favorite-header-search-input i {
    position: absolute;
    padding: 12px;
    color: #f27a21;
    cursor: pointer;
    font-size: 18px;
}

.favorite-header-search-control {
    padding: 13px 120px 13px 10px;
    font-size: 14px;
    color: #333333;
    line-height: normal;
    box-sizing: border-box;
    height: auto;
    border-radius: 6px;
    border: 1px solid transparent;
    background-color: #f3f3f3;
}

.favorite-header-search-control:focus {
    border: 1px solid #f27a21;
}

.favorite-header-my-favorite-nav-item {
    height: 100%;
}

.favorite-header-my-collection-nav-item {
    height: 100%;
}

.favorite-header-my-collection-nav-item-link {
    display: flex;
    align-items: center;
    color: #666;
    border-bottom: 4px solid transparent;
    font-size: 18px;
    height: 100%;
    margin-right: 30px;
    box-sizing: border-box;
    padding: 0;
}

.favorite-header-my-favorite-nav-item-link {
    height: 100%;
    display: flex;
    align-items: center;
    color: #666;
    border-bottom: 4px solid transparent;
    font-size: 18px;
    margin-right: 30px;
    box-sizing: border-box;
    padding: 0;
}
.favorite-header-my-favorite-nav-item-link:hover{
    color: #f27a21;
}
.favorite-header-my-collection-nav-item-link:hover{
    color: #f27a21;
}

.favorite-header-item-first .active {
    color: #f27a21;
    border-color: #f27a21;
}

.favorite-filter-item-div {
    border: 1px solid #e6e6e6;
    padding: 10px 15px;
    border-radius: 6px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.08);
}

.favorite-filters-div {
    display: flex;
    margin-bottom: 15px;
    margin-top: -15px;
    width: 1200px;
}

.favorite-filter-item-icon-heart {
    background-color: #f27a21;
    width: 18px;
    height: 18px;
    margin-right: 10px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.favorite-filter-item-icon-heart i {
    color: white;
    padding-top: 3px;
    font-size: 9px;
}

.favorite-filter-item-icon-star {
    background-color: #3090e9;
    width: 18px;
    height: 18px;
    margin-right: 10px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.favorite-filter-item-icon-star i {
    color: white;
    font-size: 10px;
    padding-top: 2px;
}

.favorite-filter-item-icon-arrow {
    background-color: #d0021b;
    width: 18px;
    height: 18px;
    margin-right: 10px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.favorite-filter-item-icon-arrow i {
    color: white;
    font-size: 10px;
}

.favorite-filter-item-icon-coupon {
    background-color: #f27a21;
    width: 18px;
    height: 18px;
    margin-right: 10px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.favorite-filter-item-icon-coupon i {
    color: white;
    font-size: 10px;
    padding-top: 2px;
}

.favorite-filter-item-div span {
    color: #333333;
    font-size: 14px;
    font-weight: 600;
}

.favorite-filter-item-div:nth-child(1) {
    border: 2px solid #f27a21;
}
.favorite-list-empty-div{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 1200px;
    height: 396px;
    background-color: white;
    border: 1px solid #eaeaea;
    border-radius: 3px;
}
.favorite-list-empty-div i{
    background-color: #fff0e4;
    color: #f27a21;
    width: 72px;
    height: 72px;
    padding: 25px;
    border-radius: 50%;
    box-sizing: border-box;
    font-size: 22px;
}
.favorite-list-empty-div p{
    font-size: 18px;
    margin-top: 8px;
    margin-bottom: 0;
    color: #f27a21;
}
.favorite-list-empty-div span{
    font-size: 14px;
    margin-top: 5px;
}
.favorite-list-empty-div-button-div{
    width: 243px;
    height: 50px;
    margin: 30px auto 0;
    display: block;
}
.favorite-list-empty-div-button{
    width: 100%;
    height: 100%;
    border-radius: 3px;
    font-size: 18px;
    font-weight: 700;
}
.favorite-list-div{
    width: 1200px;
}
