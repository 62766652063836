.bi-exclamation-circle {
    margin-right: 5px;
}

.bi-facebook {
    color: #ffffff;
}

.bi-google {
    color: #ffffff;
}

.card-form-tabs {
    background-color: #f2f2f2;
    font-size: 16px;
}

.facebook-icon {
    background-color: #4b6ea8;;
    border-radius: 4px;
    width: 30px;
    height: 30px;
}

.forget-password {
    font-size: 14px;
}

.google-icon {
    background-color: #f04236;
    border-radius: 4px;
    width: 30px;
    height: 30px;
}

.label {
    font-size: 15px;
}

.login-sign-up-card {
    width: 374px;
    padding: 24px;
}

.login-input-container {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
}

.login-input-container i {
    position: absolute;
}

.login-input-icon {
    padding: 10px;
    font-size: 18px;
}

.login-input {
    padding-right: 30px;
}

.login-page-sign-in {
    font-size: 12px;
    line-height: 14px;
}

.login-text-validation {
    color: #b30000;
    background-color: #fff1f1;
    padding: 10px;
    border: 1px solid #b30000;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.login-validation-input {
    border: 1px solid #b30000;
    background-color: #fff1f1;
}

.login-validation-input:focus {
    border: 1px solid #b30000;
    background-color: #fff1f1;
}

.nav-div {
    width: 374px;
}

.sign-button {
    width: 148px;
    margin-right: 15px;
}

.sign-button1 {
    width: 148px;
}
