@font-face {
    font-family: 'source_sans_proregular';
    src: url('https://cdn.dsmcdn.com/web/production/fonts-sourcesanspro-regular-web-font.woff2') format('woff');
    font-weight: normal;
    font-style: normal;
}

.btn-primary {
    --bs-btn-bg: #f27a21;
    --bs-btn-border-color: #f27a21;
    --bs-btn-hover-bg: #ed8e47;
    --bs-btn-hover-border-color: #ed8e47;
    --bs-btn-active-bg: #ed8e47;
    --bs-btn-active-border-color: #ed8e47;
    --bs-btn-disabled-bg: #dddddd;
    --bs-btn-disabled-border-color: #dddddd;
    --bs-btn-disabled-color: #666666;
    --bs-btn-disabled-opacity: 1;
}

.btn-secondary {
    --bs-btn-bg: #fff;
    --bs-btn-border-color: #e6e6e6;
    --bs-btn-hover-bg: #fafafa;
    --bs-btn-hover-border-color: #fafafa;
    --bs-btn-active-bg: #ffffff;
    --bs-btn-active-border-color: #fafafa;
    --bs-btn-active-color: #000000;
    --bs-btn-hover-color: #000000;
    --bs-btn-color: #000000;
}

.btn-warning {
    --bs-btn-bg: #0BC15C;
    --bs-btn-hover-bg: #00AA4C;
    --bs-btn-hover-border-color: #00AA4C;
    --bs-btn-border-color: #0BC15C;
    --bs-btn-color: #ffffff;
    --bs-btn-hover-color: #ffffff;
    --bs-btn-active-bg: #00AA4C;
    --bs-btn-active-border-color: #00AA4C;
    --bs-btn-active-color: #ffffff;

}
.btn-danger{
    --bs-btn-bg: #f27a21;
    --bs-btn-border-color: #f27a21;
    --bs-btn-hover-bg: #f27a21;
    --bs-btn-hover-border-color: #f27a21;
    --bs-btn-active-bg: #f27a21;
    --bs-btn-active-border-color: #f27a21;
    --bs-btn-disabled-bg: #f27a21;
    --bs-btn-disabled-border-color: #f27a21;
    --bs-btn-disabled-color: #ffffff;
    --bs-btn-disabled-opacity: 0.4;
}

.btn-link {
    --bs-btn-color: #333333;
    --bs-btn-hover-color: #333333;
}

.card {
    --bs-card-border-radius: 0;
    --bs-card-title-spacer-y: 0;
    --bs-card-img-overlay-padding: 8px;
}

:root {
    --bs-body-font-size: 12px;
    --bs-body-font-family: source_sans_proregular;
    --bs-body-bg: #fafafa
}

.form-check-input {
    border: 2px solid #999999;
    width: 20px;
    height: 20px;
}

.form-check-input:focus {
    box-shadow: 0 0 transparent;
    border-color: #999999;
}

.form-check-input-validation {
    border: 2px solid #b30000;
    width: 20px;
    height: 20px;
}

.form-check-input:checked {
    background-color: #f27a21;
    border: 2px solid #f27a21;
}

.form-control {
    background-color: #fafafa;
}

.form-control:focus {
    box-shadow: 0 0 transparent;
    border-color: #ced4da;
}

.nav {
    --bs-nav-link-color: #333333;
    --bs-nav-link-hover-color: #333333;
}

.list-group {
    --bs-list-group-color: #ffffff;
    --bs-list-group-bg: #000000;
    --bs-list-group-border-width: 0;
    --bs-list-group-active-bg: #000000;
}

.nav-tabs {
    --bs-nav-tabs-link-active-color: #f27a21;
}

.list-group-item:hover {
    color: white;
    text-decoration: underline;
    cursor: pointer;
}

.navbar {
    --bs-navbar-color: black;
}

.nav-link:focus {
    color: black;
}

.accordion {
    height: 100vh;
    overflow: hidden scroll;
    --bs-accordion-btn-icon-width: 12px;
    --bs-accordion-active-color: #333333;
    position: sticky;
    position: -webkit-sticky;
    top: 0;
}

.accordion-button:focus {
    box-shadow: 0 0 transparent;
    background-color: #ffffff;
    color: #333333;
}

.accordion-button {
    font-size: 13px;
    color: #333333;
    margin-bottom: 8px;
    font-weight: 600;
    padding: 15px 5px 5px 5px;
}

.accordion-item {
    font-size: 13px;
    border-left: 0;
    border-top: 0;
}

.card-title {
    font-size: 14px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.form-select {
    background: url('https://cdn.dsmcdn.com/web/assets/headerAccountNavArrow-new.png') #fff 93% center no-repeat;
}

