.product-list-card {
    margin-bottom: 20px;
    cursor: pointer;
    border: 1px solid #e6e6e6;
    box-sizing: border-box;
    border-radius: 8px;
    height: 496px;
}

.product-list-card-img-overlay {
    display: inline-flex;
    border: 1px solid #3ec461;
    color: #ffffff;
    background-color: #3ec461;
    border-radius: 4px;
    width: 72px;
    height: 24px;
    box-sizing: border-box;
    align-items: center;
    padding: 2px;
}

.bi-star-fill {
    color: #fec000;
    font-size: 10px;
}

.truck-icon-component {
    font-size: 20px;
}

.star-icon {
    font-size: 10px;
    color: #d8d8d8;
}

.product-favorite-icon {
    float: right;
    width: 43px;
    height: 43px;
    background: url('https://cdn.dsmcdn.com/web/production/favorite-heart.svg') no-repeat;
}

.product-favorite-icon-fill {
    background: url('https://cdn.dsmcdn.com/web/assets/favorite-heart-active.svg') no-repeat;
    float: right;
    width: 43px;
    height: 43px;
}
.product-favorite-icon-div {
    position: absolute;
    right: 5px;
    top: 5px;
}
.product-favorite-icon:active {
    background: url('https://cdn.dsmcdn.com/web/assets/favorite-heart-active.svg') no-repeat;
}

.product-favorite-icon:hover {
    background: url('https://cdn.dsmcdn.com/web/assets/favorite-heart-hover.svg') no-repeat;
}

.product-cross-icon {
    position: absolute;
    right: 7px;
    top: 8px;
    border: 1px solid #F0F0F0;
    background: #FFFFFF;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.104988);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.product-cross-icon i {
    margin-top: 3px;
}

.product-cross-icon:hover {
    border: 1px solid #f27a21;
}

.product-cross-icon i:hover {
    color: #f27a21;
}

.color-options {
    display: flex;
    align-items: center;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgb(0 0 0 / 10%);
    background-color: #ffffff;
    position: absolute;
    right: 10px;
    bottom: 130px;
    cursor: pointer;
    column-gap: 5px;
    justify-content: center;
    padding: 3px 6px;
    z-index: 5;
}

.color-options div {
    display: flex;
    align-items: center;
}

.color-options div .color-option:nth-child(1) {
    width: 12px;
    height: 12px;
    border: solid 1px #ffffff;
    background-color: red;
    border-radius: 50%;
    background-image: linear-gradient(to right, #3023ae 0%, #53a0fd 48%, #b4ec51 101%);
    z-index: 2;
}

.color-options div .color-option:nth-child(2) {
    background-image: linear-gradient(to right, #6bceb4 0%, #f98cc2 48%, #fff781 101%);
    z-index: 1;
    margin-left: -6px;
    width: 12px;
    height: 12px;
    border: solid 1px #ffffff;
    background-color: pink;
    border-radius: 50%;
}

.product-list-favorite-buttons-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 33px;
    position: absolute;
    bottom: 10px;
    padding: 0 9px;
    width: 100%;
}

.product-list-favorite-size-button:disabled {
    height: 32px;
    width: 80px;
    font-size: 14px;
    line-height: 1.14;
    padding: 0;
    background-color: white;
    border: 1px solid #e6e6e6;
    border-radius: 3px;
    color: #666666;
}

.product-list-favorite-add-cart-button {
    border: 1px solid #f27a21;
    width: 116px;
    height: 32px;
    padding: 0;
    font-size: 14px;
    line-height: 1.14;
    color: #f27a21;
    font-weight: 600;
    background-color: white;
    border-radius: 3px;
}
