.user-address-info-modal-header {
    padding: 14px 20px;
}
.user-address-info-modal-form-button {
    margin-bottom: 22px;
    border-radius: 6px;
    font-size: 14px;
    padding: 12px;
    background-color: #f2f2f2;
    border: 2px solid #e6e6e6;
    color: #666666;
    --bs-btn-active-bg: #f2f2f2;
    --bs-btn-active-border-color: #e6e6e6;
    --bs-btn-active-color: #666666;
}

.user-address-info-modal-form-button:hover {
    background-color: #f2f2f2;
    border-color: #e6e6e6;
    color: #666666;
}

.user-address-info-modal-form-button:focus {
    background-color: #f2f2f2;
    border: 2px solid #e6e6e6;
    color: #666666;
    border-radius: 6px;
    --bs-btn-active-bg: #f2f2f2;
    --bs-btn-active-border-color: #e6e6e6;
    --bs-btn-active-color: #666666;
}

.user-address-info-modal-form-button-selected{
    border: 2px solid #f27a21;
    margin-bottom: 22px;
    border-radius: 6px;
    font-size: 14px;
    padding: 12px;
    background-color: #f2f2f2;
    color: #f27a21;
    --bs-btn-active-bg: #f2f2f2;
    --bs-btn-active-border-color: #e6e6e6;
    --bs-btn-active-color: #666666;
}
.user-address-info-modal-form-button-selected:hover{
    background-color: #f2f2f2;
    border-color: #f27a21;
    color: #f27a21;
}
.user-address-info-modal-form-save-button {
    border-radius: 6px;
    height: 48px;
    font-size: 18px;
}

.modal-form-validation-text {
    color: #b30000;
    font-size: 12px;
    line-height: 16px;
    font-weight: 700;
}

.address-save-modal-form-control-validation {
    border: 1px solid #b30000;
    background-color: #fff1f1;
}

.address-save-modal-form-control-validation:focus {
    border: 1px solid #b30000;
    background-color: #fff1f1;
}
.address-save-modal-component{
    --bs-modal-padding: 15px 20px 20px;
}
