.product-fast-delivery-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #EFFBF5;
    border-radius: 8px;
    height: 68px;
    margin-bottom: 15px;
    padding: 0 20px;
}

.product-fast-delivery-text {
    color: #333333;
    font-size: 16px;
}

.truck-icon {
    color: #0BC15C;
    margin-right: 15px;
    font-size: 25px;
}

.product-filter-select {
    width: 180px;
    border: 1px solid #e2e2e2;
    color: #666666;
    font-size: 12px;
}
.product-filter-select-container{
    margin-bottom: 15px;
    padding-left: 0;
    padding-right: 0;
}
.product-item-card-container{

}

