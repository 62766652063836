.user-info-new-add-address-container {
    border: 1px solid #e2e2e2;
    height: 66px;
    padding: 0 20px;
    border-radius: 3px;
}

.user-info-new-add-address-button i {
    color: #f27a21;
    font-size: 20px;
    margin-right: 7px;
    vertical-align: middle;
}

.user-info-new-add-address-button {
    text-decoration: none;
    font-size: 14px;
    color: #333333;
    font-weight: 700;
}

.user-info-new-add-address-button:hover {
    text-decoration: underline;
}

.user-info-addresses-container {
    border: 1px solid #e2e2e2;
    margin-top: 15px;
    box-sizing: border-box;
    padding: 0 20px;
    width: 100%;
    border-radius: 6px;
    min-height: 460px;
}

.modal-saved-check-icon{
    color: #0BC15C;
    font-size: 38px;
    font-family: source_sans_prosemibold,sans-serif;
}
.modal-saved-check-icon-div{
    width: 84px;
    height: 84px;
    background-color: #dfffed;
    border-radius: 41.5px;
    align-items: center;
    display: flex;
    align-self: center;
    justify-content: center;
}
.saved-confirmation-modal-container{
    padding: 0;
    width: 460px;
    border-radius: 0;
}
.saved-confirmation-modal-body{
    padding: 29px 21px 11px;
}
.modal-saved-x-icon-div{
    position: absolute;
    right: 12px;
    top: 8px;
}
.bi-x{
    cursor: pointer;
    font-size: 25px;
    color: #999999;
}
