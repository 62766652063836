.elite-header-container {
    padding: 20px;
    border: 1px solid #e6e6e6;
    border-radius: 8px;
}

.elite-header-container h1 {
    font-size: 18px;
    font-weight: 600;
    color: #333333;
    margin-bottom: 15px;
    line-height: 23px;
}

.elite-header-container div p {
    font-size: 14px;
    line-height: 1;
    color: #333333;
    margin-bottom: 0;
}

.elite-header-divs {
    display: flex;
    align-items: center;
    margin-top: 12px;
}

.elite-header-divs div {
    display: flex;
    background-color: #f5f5f5;
    border-radius: 4px;
    padding: 11px;
    align-items: center;
}

.elite-header-divs div:nth-child(2) {
    margin-left: 12px;
}

.elite-header-divs div i {
    font-size: 18px;
    color: #f27a21;
}

.elite-header-divs div span {
    font-size: 14px;
    color: #333333;
    padding-left: 11px;
}

.elite-info-container {
    padding: 20px;
    border: 1px solid #e6e6e6;
    border-radius: 8px;
    margin-top: 20px;
}

.elite-info-container span {
    font-size: 14px;
    color: #333333;
}

.elite-progress-bar-header {
    display: flex;
    justify-content: space-between;
    padding: 20px 0 12px;
}

.elite-progress-bar-header-point-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.elite-progress-bar-header-point-container div {
    width: 18px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #ffc000;
    margin-right: 8px;
}

.elite-progress-bar-header-point-container .bi-star-fill {
    color: white;
    font-size: 11px;
}

.elite-progress-bar-header-point-container span:nth-child(2) {
    font-size: 14px;
    color: #666666;
    line-height: 18px;
    margin-left: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.elite-progress-bar-header-point-container span:nth-child(2) b {
    font-size: 18px;
    color: #333333;
    margin-left: 4px;
    font-weight: 600;
}

.elite-progress-bar-header-point-container span:nth-child(3) {
    color: #f27a21;
    width: 12px;
    height: 12px;
    margin-left: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.elite-progress-bar-header-point-container .bi-info-circle {
    color: #f27a21;
    line-height: 16px;
    margin-left: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.elite-progress-bar-history-button {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding-right: 6px;
}

.elite-progress-bar-history-button span {
    font-size: 14px;
    color: #f27a21;
    margin-right: 10px;
}

.elite-progress-bar-history-button i {
    font-size: 12px;
    color: #f27a21;
}

.elite-progress-bar-container {
    display: flex;
    height: 125px;
    background-color: #fff9eb;
    border-radius: 4px;
    padding: 20px 30px;
    box-sizing: border-box;
    align-items: flex-start;
}

.elite-point-delay-info-container {
    display: flex;
    align-items: center;
    background-color: #f5f5f5;
    border-radius: 4px;
    margin-top: 12px;
}

.elite-point-delay-info-container i {
    padding: 12px 6px 13px 12px;
    color: #666666;
    font-size: 12px;
}

.elite-point-delay-info-container div {
    padding: 12px 12px 12px 0;
    line-height: 15px;
    color: #666666;
    font-size: 12px;
}

.elite-point-delay-info-container div p {
    margin-bottom: 0;
    font-weight: bold;
}

.progress-bar-item-container {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
}

.progress-bar-items {
    width: 100%;
    height: 6px;
    background-color: #e6e6e6;
    border-radius: 4px;
}

.progress-bar-item-icon {
    position: absolute;
    background-color: #ffc000;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    box-sizing: border-box;
    border: 4px solid #fff9eb;
    right: 0;
    left: 0;
    top: -13px;
}

.progress-bar-item-icon .bi-star-fill {
    font-size: 14px;
    color: white;
}

.progress-bar-badge {
    position: relative;
}

.progress-bar-badge-item {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 50%;
    border: 4px solid #fff9eb;
    background-color: #fff;
    box-sizing: border-box;
}

.progress-bar-badge-item span {
    min-width: 24px;
    width: 24px;
    height: 24px;
    min-height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1px solid #e6e6e6;
    box-sizing: border-box;
}

.progress-bar-badge-item .bi-ticket-perforated-fill {
    font-size: 15px;
    color: #666666;
}

.progress-bar-badge-item .bi-suit-diamond-fill {
    font-size: 15px;
    color: #666666;
}

.progress-bar-badge-item-info {
    width: 230px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 50%;
    font-family: source_sans_proregular, sans-serif;
    text-align: center;
    transform: translate(-50%, 2px);
}

.progress-bar-badge-item-info p:nth-child(1) {
    font-size: 14px;
    line-height: 18px;
    color: #333333;
    font-weight: 600;
    margin-bottom: 0;
}

.progress-bar-badge-item-info p:nth-child(2) {
    font-size: 12px;
    color: #666666;
    line-height: 15px;
    margin-bottom: 0;
}

.progress-bar-items-inner {
    position: relative;
}

.elite-point-calculator-container {
    display: flex;
    justify-content: space-between;
    background-color: #fff0f4;
    border-radius: 8px;
    height: 64px;
    margin-top: 20px;
}

.elite-point-calculator-item-first {
    margin-left: 18px;
    display: flex;
    align-items: center;
}

.elite-point-calculator-item-first span {
    margin-left: 6px;
    font-weight: 600;
    font-size: 16px;
    color: #333333;
}

.elite-point-calculator-item-first i {
    margin-left: 7px;
    font-size: 12px;
    color: #ff4988;
}

.elite-point-calculator-item-second {
    display: flex;
    margin-right: 20px;
    cursor: pointer;
}

.elite-point-calculator-button {
    display: flex;
    align-items: center;
}

.elite-point-calculator-button span {
    font-size: 14px;
}

.elite-point-calculator-button div {
    height: 24px;
    width: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 8px;
    border-radius: 8px;
    background-color: white;
}

.elite-point-calculator-button .bi-chevron-right {
    color: #ff4988;
    font-size: 12px;
}

.elite-points-container {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.elite-points-item-first {
    display: flex;
    align-items: center;
}

.elite-points-item {
    display: flex;
    align-items: center;
    border-radius: 4px;
    width: 520px;
    height: 56px;
    margin-right: 12px;
    box-sizing: border-box;
    justify-content: space-between;
    border: 1px solid #e6e6e6;
    padding: 12px;
    margin-top: 15px;
}
.elite-points-item:nth-child(1){
    margin-top: 0;
}
.elite-points-item:nth-child(2){
    margin-top: 0;
    margin-right: 0;

}
.elite-points-item:nth-child(4){
    margin-right: 0;
}
.elite-points-item:nth-child(6){
    margin-right: 0;
}

.elite-points-item-first-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    width: 32px;
    height: 32px;
    margin-right: 12px;
    box-sizing: border-box;
    background-color: #ffc000;
    border-color: #ffc000;
}

.elite-points-item-first-icon i {
    color: white;
    font-size: 16px;
}

.elite-points-item-first span {
    color: #333333;
    font-size: 14px;
    font-weight: bold;
}

.elite-points-item-second {
    display: flex;
    align-items: center;
    margin-right: 20px;
    width: 103px;
}

.elite-points-item-second-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    background-color: #ffc000;
    margin-right: 10px;
}

.elite-points-item-second-icon i {
    color: white;
    font-size: 12px;
}

.elite-points-item-second-desc {
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.elite-points-item-second-desc span {
    color: #333333;
    font-size: 14px;
    font-weight: bold;
}
.elite-points-item-first-icon-meal{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    width: 32px;
    height: 32px;
    margin-right: 12px;
    box-sizing: border-box;
    background-color: #f27a21;
    border-color: #f27a21;
}
.elite-points-item-first-icon-meal i{
    color: white;
    font-size: 19px;
}
.elite-points-item-first-icon-bag{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    width: 32px;
    height: 32px;
    margin-right: 12px;
    box-sizing: border-box;
    background-color: #0bc15c;
    border-color: #0bc15c;
}
.elite-points-item-first-icon-bag{
    color: white;
    font-size: 16px;
}
.elite-points-item-first-icon-closet{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    width: 32px;
    height: 32px;
    margin-right: 12px;
    box-sizing: border-box;
    background-color: #25d6a2;
    border-color: #25d6a2;
}
.elite-points-item-first-icon-closet{
    color: white;
    font-size: 19px;
}
.elite-points-item-first-icon-chat{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    width: 32px;
    height: 32px;
    margin-right: 12px;
    box-sizing: border-box;
    background-color: #ff4988;
    border-color: #ff4988;
}
.elite-points-item-first-icon-chat i{
    color: white;
    font-size: 17px;
}
.elite-points-item-first-icon-camera{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    width: 32px;
    height: 32px;
    margin-right: 12px;
    box-sizing: border-box;
    background-color: #4a90e2;
    border-color: #4a90e2;
}
.elite-points-item-first-icon-camera i{
    color: white;
    font-size: 21px;
}
.elite-points-items-div{
    display: flex;
    flex-wrap: wrap;
}

