.user-address-info-card {
    height: 232px;
    border-radius: 3px;
    border: 1px solid #e2e2e2;;
    margin-top: 15px;
    margin-bottom: 5px;
}

.user-address-details-component-text {
    margin-bottom: 0;
    line-height: 24px;
    font-size: 12px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.user-address-component-footer {
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    bottom: 15px;
    left: 15px;
    right: 15px;
}

.user-address-component-card-button {
    width: 120px;
    background: white;
    color: #f27a21;
    font-size: 14px;
    border-radius: 3px;
    line-height: 18px;
}

.user-address-component-delete-span {
    font-size: 14px;
    line-height: 18px;
}

.user-address-component-delete-span:hover {
    text-decoration: underline;
}

.user-address-info-trash-icon {
    font-size: 35px;
    margin-bottom: 15px;
}

.user-address-delete-confirmation-modal-container {
    width: 300px;
    padding: 0;
    border-radius: 0;
}

.user-address-delete-confirmation-modal-body {
    padding: 20px;
}

.user-address-component-delete-modal-div{
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 20px;
}
.user-address-component-delete-modal-button-div {
    display: flex;
    justify-content: space-between;
    background: #fbfbfb;
    border: 1px solid #e8e8e8;
    width: 100%;
}

.user-address-component-delete-modal-button {
    border-radius: 3px;
    border: 1px solid #d4d4d4;
    margin: 10px 20px;
    width: 100%;
    padding: 6px 10px;
    font-size: 14px;
    line-height: 18px;
    background: 0 0;
    color: #666666;
}
.user-address-component-delete-modal-button:hover{
    border: 1px solid #d4d4d4;
    background: 0 0;
    color: #666666;
}

.modal-delete-x-icon-div {
    position: absolute;
    right: 12px;
    top: 8px;
    display: inline;
}
